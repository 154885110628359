import Script from 'next/script';
import type { ReactElement } from 'react';
import { useEffect, useId, useMemo, useState } from 'react';
import styled from 'styled-components';
import type { VideoContent } from '../../../amplienceTypes/schemas/exported/section-video-schema';
import Attributes from './Attributes';
import { SCHEMA_CONTEXT, schemaType } from '../../../utilities/constants/schemaTypes';
import { useConsentContext } from '~/utilities/context/dynamic/ConsentContext';

const S = {
  EmbedContainer: styled.div`
    position: relative;
    padding-top: 56.25%;
  `,

  Iframe: styled.iframe`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  `,
};

export const ContentBlockVideo = ({ video, attributes }: VideoContent): ReactElement => {
  const { hasAnalyticsConsent, hasMarketingConsent } = useConsentContext();
  const cocoFullConsent = hasAnalyticsConsent && hasMarketingConsent;

  const id = useId();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const videoSchema = useMemo(() => {
    if (!video?.schema) {
      return undefined;
    }

    const { clip, ...rest } = video.schema;

    return {
      '@context': SCHEMA_CONTEXT,
      '@type': schemaType.videoObject,
      ...rest,
      hasPart: clip,
    };
  }, [video]);

  return (
    <>
      {videoSchema && (
        <Script
          id={`section-video-schema-${id}`}
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: `${JSON.stringify(videoSchema)}` }}
        />
      )}
      {video?.id && (
        <Attributes
          {...attributes}
          additionalClassNames="contentBlock contentBlock-video js-contentBlock"
          style={video.padding ? { margin: '4%', width: 'auto' } : {}}
        >
          {/* Check DOM loaded because of external video fetch causing hydration issue for ssr */}
          {loaded && (
            <S.EmbedContainer className="embedContainer">
              {video.type === 'YouTube' && (
                <S.Iframe
                  title="youtube content video iframe"
                  width="100%"
                  height="100%"
                  src={`https://www.youtube${cocoFullConsent ? '' : '-nocookie'}.com/embed/${
                    video.id
                  }?modestbranding=1&rel=0${video.autoplay ? '&autoplay=1' : ''}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
              )}
              {video.type === 'Vimeo' && (
                <S.Iframe
                  title="vimeo content video iframe"
                  width="100%"
                  height="100%"
                  src={`https://player.vimeo.com/video/${video.id}/?dnt=${cocoFullConsent ? 0 : 1}${
                    video.autoplay ? '&muted=1&autoplay=1&loop=1&background=1' : ''
                  }`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
              )}
            </S.EmbedContainer>
          )}
        </Attributes>
      )}
    </>
  );
};
